import { client } from "#apis/axios";
import { transformBriefDetailsResponse } from "#apis/briefs/transform";
import { GetBriefDetailsRequest, GetBriefDetailsResponse } from "#customTypes/brief";
import { Brief } from "#customTypes/db";
import { getHeaders } from "#shared/components/api/utils";
import { TransformUrlParam } from "#shared/utils/transform.utils";
import { AxiosHeaders } from "axios";

export namespace BriefsApi {
  const BASE_PREFIX = "/briefs";

  export const fetchBriefDetails = async (
    params: GetBriefDetailsRequest
  ): Promise<Brief> => {
    const { cookies, briefRef, organisationId } = params;

    const { data } = await client.get<GetBriefDetailsResponse>(
      `${BASE_PREFIX}/${TransformUrlParam.brief(briefRef)}`,
      {
        params: {
          organisationId,
          includeContent: "html",
        },
        headers: getHeaders(cookies) as AxiosHeaders,
      }
    );

    return transformBriefDetailsResponse(data);
  };
}
