export enum FeedChannelTypes {
  default = "default",
  private = "private",
}

export enum NavigationItem {
  foryou = "feed/foryou",
  following = "feed/following",
}

enum OAUTH_STRATEGIES {
  google = "google",
  apple = "apple",
  facebook = "facebook",
  microsoft = "microsoft",
  email = "email",
}

enum MAIL_ME_STATUS {
  mail_me = "mail_me",
  enter_email = "enter_email",
  check_inbox = "check_inbox",
}

enum FEED_COUNT_TYPES {
  comments = "comments",
  bulbshares = "bulbshares",
  responses = "responses",
}

enum ERROR_CODES {
  USER_ALREADY_REGISTERED = 688,
  ACTIVE_VPN = 701,
}

const ERROR_MESSAGES: {
  [key in ERROR_CODES]?: string;
} = {
  [ERROR_CODES.USER_ALREADY_REGISTERED]: "user_already_registered",
  [ERROR_CODES.ACTIVE_VPN]: "active_vpn",
};

export const PROTECTED_PAGES_REGEX =
  /\/?((screener\/(?!.*success).+)|(feed)|(channel)|(profile)|(survey)|(business-lab)|(business-centre))/;

export {
  OAUTH_STRATEGIES,
  MAIL_ME_STATUS,
  FEED_COUNT_TYPES,
  ERROR_CODES,
  ERROR_MESSAGES,
};

export const BRIEF_COMMENTS_SECTION_WIDTH = 420;

export enum EMAIL_PROVIDERS {
  GMAIL = "Gmail",
  OUTLOOK = "Outlook",
  YAHOO = "Yahoo",
  AOL = "AOL",
  APPLE = "Mail",
}

export const EMAIL_PROVIDERS_LINK = {
  [EMAIL_PROVIDERS.GMAIL]: "https://mail.google.com",
  [EMAIL_PROVIDERS.OUTLOOK]: "https://outlook.live.com",
  [EMAIL_PROVIDERS.YAHOO]: "https://mail.yahoo.com",
  [EMAIL_PROVIDERS.AOL]: "https://mail.aol.com",
  [EMAIL_PROVIDERS.APPLE]: "message://",
};

export const EMAIL_PROVIDER_DOMAINS = [
  {
    domains: [
      "outlook.",
      "hotmail.",
      "live.",
      "msn.",
      "microsoft.",
      "office365.",
      "exchange.",
      "onmicrosoft.",
    ],
    provider: EMAIL_PROVIDERS.OUTLOOK,
  },
  { domains: ["gmail.", "googlemail."], provider: EMAIL_PROVIDERS.GMAIL },
  { domains: ["yahoo."], provider: EMAIL_PROVIDERS.YAHOO },
  { domains: ["aol."], provider: EMAIL_PROVIDERS.YAHOO },
  { domains: ["icloud.", "me.com", "apple."], provider: EMAIL_PROVIDERS.APPLE },
];

export enum HTTP_STATUS {
  OK = 200,
  INTERNAL_SERVER_ERROR = 500,
}

export enum ERROR_TYPES {
  "user_engagement_complete_registration_fail" = "user_engagement_complete_registration_fail",
  "user_engagement_screener_brief_already_taken" = "user_engagement_screener_brief_already_taken",
  "user_engagement_profile_profile_save_fail" = "user_engagement_profile_profile_save_fail",
  "user_engagement_screener_expired" = "user_engagement_screener_expired",
  "mail_fail_authentication" = "mail_fail_authentication",
  "provider_fail_authentication" = "provider_fail_authentication",
  "submit_poll_responses_fail" = "submit_poll_responses_fail",
  "submit_poll_brief_not_found" = "submit_poll_brief_not_found",
  "user_engagement_not_found" = "user_engagement_not_found",
  "user_engagement_uncompleted_registration" = "user_engagement_uncompleted_registration",
  "user_engagement_uncompleted_and_brief_already_taken" = "user_engagement_uncompleted_and_brief_already_taken",
}

export const DEEP_LINK_INVITE = "invite";

export const LINK_FALLBACKS = {
  about: "https://bulbshare.com/en/about/",
  faq: "https://resources.bulbshare.com/faq/",
  privacy: "https://resources.bulbshare.com/privacy-notice/",
  terms: "https://resources.bulbshare.com/terms/",
  blog: "https://resources.bulbshare.com/",
  careers: "https://bulbshare.com/en/careers/",
};

export const EMAIL_FALLBACKS = {
  support: "support@bulbshare.com",
};

export const FACEBOOK_RE_REQUEST_KEY = "missingFacebookEmail";

export const GET_APP_MOBILE_BANNER_COOLDOWN_DAYS = 10;

export const TOAST_DEFAULT_TIMEOUT = 2000;

export const HELP_LINKS = {
  salesforceApiDisabled: "https://help.salesforce.com/s/articleView?id=000385535&type=1",
};

export const GOD_VIEW_STORAGE_KEY = "godView";
export const GOD_VIEW_HEADER = "superadmin";
export const WLA_HEADER = "wla";

export const USER_SETTINGS_STORAGE_KEY = "user_settings";

export const MODAL_DEFAULT_Z_INDEX = 1500;
